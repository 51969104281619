#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 2px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
  text-align: left;
  background-color: #242221;
  color: white;
}

/* @page {
  size: 220mm 140mm;
} */

/* @media print {
  @page {
    size: a5 potrait;
    margin: 0mm !important;
  }

  @media all {
    .pagebreak {
      overflow: visible;
    }
  }
} */