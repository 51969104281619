.content {
  padding: 10px;
  border: 1px solid black;
  background-color: rgb(246, 246, 246);
}

.header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  margin-bottom: 2px;
}

.receipt-header {
  padding: 2px 10px;
  border-radius: 3px;
  border: 1px dotted black;
  font-weight: 500;
}

.school-title {
  font-size: 16px;
  text-transform: uppercase;
}

.school-address {
  font-size: 12px;
}

.receipt-date {
  margin-top: 2px;
}

.student-info {
  margin-top: 3px;
  margin-bottom: 3px;
}

.student-info p {
  font-weight: 500;
  margin-top: 2px;
}

.footer {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.footer-amount {
  padding: 0px 10px;
  border-radius: 3px;
  border: 1px dotted black;
  font-weight: 500;
  display: flex;
}

.footer-authorized {
  padding-top: 10px;
}

/* @media print {
  @page {
    size: a4 landscape;
    margin: 0mm !important;
  }

  @media all {
    .pagebreak {
      overflow: visible;
    }
  }
} */

table.intimate,
td.intimate,
th.intimate {
  border: 1px solid;
}

table.intimate {
  width: 100%;
  border-collapse: collapse;
  margin: 6px 0px;
}

.school-detail {
  display: flex;
  gap: 10px;
}